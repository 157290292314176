<template>
	<!-- 订单管理 -->
	<div style="background: #eee">
		<van-sticky>
			<van-nav-bar title="订单管理" left-text="返回" left-arrow @click-left="goback" />
			<!-- 标签页 -->
			<van-tabs v-model="activeName" swipeable @click="tabChange">
				<div v-for="(item, index) in orderTabs" :key="index">
					<van-tab v-if="item.num > 0" :badge="item.num" :title="item.title" :name="item.type" />
					<van-tab v-if="item.num <= 0" :title="item.title" :name="item.type" />
				</div>

				<van-search
					v-model="params.content"
					show-action
					placeholder="门店/单号/客户名/客户电话"
					@search="onSearch"
				>
					<template #action>
						<div @click="onSearch()" style="color: red">查询</div>
					</template>
				</van-search>
			</van-tabs>
		</van-sticky>
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-cell-group
				v-for="(item, index) in orderManageList"
				:key="index"
				style="margin: 1.2rem; text-align: left"
				inset
			>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #ffd01e"
					v-show="item.status == 8"
				>
					待派单
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #1989fa"
					v-show="item.status == 1"
				>
					待首联
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #1989fa"
					v-show="item.status == 9"
				>
					电表申请
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #4014cf"
					v-show="item.status == 13"
				>
					待勘测
				</div>
				<div style="text-align: right; margin: 0.5rem 1rem; color: green" v-show="item.status == 2">
					待安装
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #c1c2dc"
					v-show="item.status == 3"
				>
					待审核
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #ee0a24"
					v-show="item.status == 4"
				>
					被驳回
				</div>
				<!-- <div
					style="text-align: right; margin: 0.5rem 1rem; color: #646566"
					v-show="item.status == 5"
				>
					暂不安装
				</div> -->
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #ee0a24"
					v-show="item.status == 6"
				>
					待结算
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #80007e"
					v-show="item.status == 7"
				>
					已结算
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #004f80"
					v-show="item.status == 12"
				>
					已取消
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #805700"
					v-show="item.status == 10"
				>
					等客户通知
				</div>
				<div
					style="text-align: right; margin: 0.5rem 1rem; color: #804900"
					v-show="item.status == 11"
				>
					取消待审核
				</div>
				<van-field
					label="单号"
					:value="item.orderNum"
					input-align="right"
					readonly
					label-width="6rem"
				/>
				<van-field
					label="门店"
					:value="item.storeName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="客户名"
					:value="item.customerName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="联系电话"
					:value="item.phone"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<!--        <van-field label="所属区域" :value="item.regionName" input-align="right" readonly label-width="8rem"/>-->
				<van-field
					label="详细地址"
					:value="item.detailAddress"
					input-align="right"
					type="textarea"
					rows="1"
					autosizeinput-align="right"
					label-width="8rem"
					readonly
				/>
				<van-field
					label="预约单号"
					:value="item.pileModel"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="ID"
					:value="item.reserve2"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="订单金额"
					:value="item.price"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="车架号"
					:value="item.frameNo"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="录单人"
					:value="item.recordUserName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="派单人"
					:value="item.dispatchUserName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="负责师傅"
					:value="item.masterWorkerName"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="录单时间"
					:value="dateFormat(item.recordTime)"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="派单时间"
					:value="dateFormat(item.dispatchTime)"
					input-align="right"
					readonly
					label-width="8rem"
				/>
				<van-field
					label="订单备注"
					:value="item.orderRemark"
					input-align="right"
					type="textarea"
					readonly
					label-width="6rem"
				/>
				<van-field>
					<template #button>
						<van-button
							type="primary"
							size="small"
							@click="distributionOrder(item.id)"
							v-show="
								authorization('/home-admin/order/order-manage/distribution') && item.status == 8
							"
							style="margin-left: 1rem"
							>派单</van-button
						>
						<van-button
							type="primary"
							size="small"
							@click="distributionOrder(item.id)"
							v-show="
								authorization('/home-admin/order/order-manage/replay') &&
								(item.status == 1 || item.status == 9 || item.status == 2)
							"
							style="margin-left: 1rem"
							>重新派单</van-button
						>
						<van-button
							type="primary"
							size="small"
							@click="toExamine(item.id)"
							v-show="authorization('/home-admin/order/order-manage/examine') && item.status == 3"
							style="margin-left: 1rem"
							>审核</van-button
						>
						<van-button
							type="default"
							size="small"
							style="margin-left: 1rem"
							@click="toDetail(item)"
							v-show="authorization('/home-admin/order/order-manage/detail')"
							>详情</van-button
						>
						<van-button
							type="danger"
							v-show="isDeleteFun(item.recordUser, item.status)"
							@click="deleteOrder(item.id)"
							readonly
							size="small"
							style="margin-left: 1rem"
							>删除</van-button
						>
					</template>
				</van-field>
			</van-cell-group>
		</van-list>
		<!-- 添加按钮 -->
		<van-button
			round
			type="info"
			v-show="authorization('/home-admin/order/order-manage/add')"
			icon="plus"
			style="position: fixed; bottom: 1rem; left: 1rem; padding: 1.6rem 1.1rem"
			@click="showAdd = true"
		></van-button>
		<!-- 弹出层 -->
		<van-popup v-model="showAdd" position="bottom">
			<van-form @submit="onSubmit">
				<van-field
					label="快速录入"
					placeholder="示例: 所属门店,客户名,联系电话,详细地址,上游预约单号,上游ID"
					type="textarea"
					row="10"
					v-model="textContent"
					@input="textInput"
					><template #button>
						<van-button native-type="button" type="primary" size="small" @click="analysisData"
							>快速录入</van-button
						>
						<br />
					</template>
				</van-field>
				<van-field
					label="ID"
					v-model="orderAddParams.reserve2"
					input-align="right"
					placeholder="请填写上游ID"
					clearable
					rows="1"
					autosizeinput-align="right"
					label-width="8rem"
				/>
				<van-field
					label="客户名"
					v-model="orderAddParams.customerName"
					required
					clearable
					input-align="right"
					label-width="8rem"
					placeholder="请填写客户名"
					:rules="[{ required: true }]"
				/>
				<van-field
					label="联系电话"
					required
					clearable
					v-model="orderAddParams.phone"
					input-align="right"
					label-width="8rem"
					placeholder="请填写联系电话"
					:rules="[{ required: true }]"
				/>
				<van-field
					label="详细地址"
					v-model="orderAddParams.detailAddress"
					input-align="right"
					placeholder="请填写详细地址"
					clearable
					type="textarea"
					rows="1"
					required
					autosizeinput-align="right"
					label-width="8rem"
					:rules="[{ required: true }]"
				/>
				<van-field
					label="下单充电桩类型"
					required
					clearable
					v-model="orderAddParams.storeName"
					input-align="right"
					label-width="8rem"
					placeholder="下单充电桩类型"
					:rules="[{ required: true }]"
				/>
				<van-field
					label="预约单号"
					v-model="orderAddParams.pileModel"
					input-align="right"
					placeholder="请填写上游预约单号"
					clearable
					rows="1"
					autosizeinput-align="right"
					label-width="8rem"
				/>


				<van-field
					label="车架号"
					v-model="orderAddParams.frameNo"
					input-align="right"
					placeholder="请填写车架号"
					clearable
					rows="1"
					autosizeinput-align="right"
					label-width="8rem"
				/>
				<van-field
					required
					readonly
					clickable
					label="所属区域"
					label-width="8rem"
					:value="orderAddParams.regionName"
					placeholder="请选择所属区域"
					@click="showPicker = true"
				/>
				<van-popup v-model="showPicker" round position="bottom">
					<van-picker
						value-key="label"
						show-toolbar
						:columns="regionList"
						@cancel="showPicker = false"
						@confirm="onConfirm"
					/>
				</van-popup>
				<!--        <van-field label="订单金额" required clearable v-model="orderAddParams.price" input-align="right"  label-width="8rem" placeholder="请填写订单金额" :rules="[{required:true}]"/>-->
				<van-field
					label="订单备注"
					v-model="orderAddParams.orderRemark"
					input-align="right"
					placeholder="请填写订单备注"
					clearable
					rows="1"
					autosizeinput-align="right"
					label-width="8rem"
				/>
				<van-button
					type="primary"
					native-type="button"
					style="margin: 1rem; float: right"
					@click="showShortcut = true"
					size="small"
					>快捷语</van-button
				>
				<div style="margin: 1rem">
					<van-button round block type="info" native-type="submit">提交</van-button>
				</div>
			</van-form>
		</van-popup>
		<van-popup v-model="showDistribution" position="bottom">
			<van-field
				style="position: absolute; top: 35px; margin: auto; z-index: 99"
				placeholder="搜索"
				v-model="searchUser"
				input-align="center"
				@input="onInput"
			></van-field>
			<van-picker
				title="负责师傅"
				show-toolbar
				value-key="userName"
				:columns="distributionCol"
				@confirm="onDistribution"
				@cancel="showDistribution = false"
			/>
		</van-popup>
		<van-popup v-model="showExamine" position="bottom">
			<van-field
				v-model="examineParams.rejectRemark"
				rows="6"
				autosize
				label="驳回理由"
				type="textarea"
				maxlength="80"
				placeholder="驳回需要填写驳回理由"
				show-word-limit
			/>
			<van-button type="danger" style="margin: 1rem; float: right" @click="confirmExamine(4)"
				>驳回</van-button
			>
			<van-button type="primary" style="margin: 1rem; float: right" @click="confirmExamine(6)"
				>通过</van-button
			>
		</van-popup>
		<van-popup v-model="showShortcut" style="width: 20rem">
			<van-picker
				value-key="name"
				:default-index="defaultIndex"
				show-toolbar
				@confirm="onShortcut"
				:columns="shortcutCol"
				@cancel="showShortcut = false"
				style="font-size: 10px"
			/>
		</van-popup>
	</div>
</template>
<script>
	import moment from 'moment';
	import { Toast } from 'vant';
	import { authorization } from '../authorization/authorization';
	export default {
		data() {
			return {
				showExamine: false,
				examineParams: {
					orderIds: [],
					orderId: '',
					rejectRemark: '',
					type: '',
				},
				searchUser: '',
				defaultIndex:
					sessionStorage.getItem('defaultIndex') === undefined
						? 0
						: Number(sessionStorage.getItem('defaultIndex')),
				activeName: this.$route.query.type === undefined ? 8 : Number(this.$route.query.type),
				showShortcut: false,
				textContent: '',
				showPicker: false,
				regionList: [],
				showAdd: false,
				loading: false,
				finished: false,
				orderTabs: [
					{ type: 8, title: '待派单订单', color: '#7af', num: 0 },
					{ type: -1, title: '全部订单', color: '#7af', num: 0 },
					{ type: 6, title: '待结算订单', color: '#fa0', num: 0 },
					{ type: 7, title: '已结算订单', color: '#f00', num: 0 },
					{ type: 1, title: '待首联订单', color: '#7af', num: 0 },
					// { type: 9, title: '电表申请中', color: '#fa0', num: 0 },
					{ type: 2, title: '待安装订单', color: '#f00', num: 0 },
          { type: 15, title: '今日待安装', color: '#f02', num: 0 },
					{ type: 3, title: '待审核订单', color: '#7af', num: 0 },
					{ type: 4, title: '被驳回订单', color: '#fa0', num: 0 },
					// { type: 5, title: '暂不安装订单', color: '#f00', num: 0 },
				],
				orderManageList: [],
				orderAddParams: {
					storeName: '',
					customerName: '',
					phone: '',
					detailAddress: '',
					pileModel: '',
					frameNo: '',
					price: '',
					regionId: '',
					regionName: '',
					orderRemark: '',
					reserve2: '',
				},
				params: {
					size: 5,
					page: 1,
					content: '',
					status: 8,
				},
				showDistribution: false,
				distribution: {
					orderId: [],
					userId: '',
				},
				distributionCol: [],
				oldDistributionCol: [],
				receivingOrder: 0,
				shortcutCol: [
					{
						name: '长安7KW充电桩，无需带桩上门，充电桩在客户手里，白色的是我们安装，11张图',
						value: 0,
					},
					{
						name: '7kw-充电桩安装施工不含电线，核销码，好评，11张图',
						value: 1,
					},
					{
						name: '7kw-充电桩30米套包，核销码，好评，11张图',
						value: 2,
					},
					{
						name: '比亚迪7kw充电桩，需要带桩上门，11张图',
						value: 3,
					},
					{
						name: '比亚迪3.3kw充电桩，需要带桩上门，11张图',
						value: 4,
					},
				],
			};
		},
		created() {
			this.queryOrderList();
			this.queryRegionList();
			this.queryUserList();
			this.getOrderCount();
		},
		methods: {
			authorization,
			toDetail(item) {
				//跳转到完成订单
				sessionStorage.setItem('orderId', item.id);
				this.$router.push({
					path: '/CompleteOrder',
					query: { orderId: item.id, goBackUrl: '/OrderManage' },
				});
			},
			confirmExamine(type) {
				if (type === 4) {
					if (
						this.examineParams.rejectRemark === '' ||
						this.examineParams.rejectRemark === undefined
					) {
						Toast.fail('请填写驳回理由');
						return;
					}
				}
				this.examineParams.type = type;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/examine-order',
					data: this.examineParams,
				}).then((res) => {
					if (res.data.code === 200) {
						if (type == 4) {
							Toast.success('驳回成功');
						} else if (type == 6) {
							Toast.success('审核通过');
						}
						this.orderManageList = [];
						this.params.page = 1;
						this.queryOrderList();
						this.showExamine = false;
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			toExamine(id) {
				this.examineParams.orderId = id;
				this.examineParams.orderIds = [];
				this.examineParams.orderIds.push(this.examineParams.orderId);
				this.showExamine = true;
			},
			getOrderCount() {
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-order-count',
				}).then((res) => {
					this.orderCount = res.data.data;
					this.orderTabs[0].num = this.orderCount.beLeaflets;
				});
			},
			tabChange(name) {
				if (name === -1) {
					this.params.status = 0;
				} else {
					this.params.status = name;
				}
				this.orderManageList = [];
				this.params.page = 1;
				this.finished = false;
				this.queryOrderList();
			},
			onSearch() {
				this.params.page = 1;
				this.queryOrderList();
			},
			textInput() {
				this.textContent = this.textContent.replace(/\r|\n|\s/gi, ' ');
			},
			onInput() {
				let newArray = [];
				for (let i = 0; i < this.distributionCol.length; i++) {
					if (this.distributionCol[i].userName.indexOf(this.searchUser) >= 0) {
						newArray.push(this.distributionCol[i]);
					}
				}
				if (this.searchUser === '' || this.searchUser === undefined) {
					this.distributionCol = this.oldDistributionCol;
				} else {
					this.distributionCol = [];
					this.distributionCol = newArray;
				}
			},
			onShortcut(val) {
				sessionStorage.setItem('defaultIndex', val.value);
				this.defaultIndex = val.value;
				this.orderAddParams.orderRemark = val.name;
				this.showShortcut = false;
			},
			analysisData() {
				console.log(this.textContent);
				let array = [];
				let arrayOld = this.textContent.split(' ');
				for (let i = 0; i < arrayOld.length; i++) {
					if (arrayOld[i] !== ' ' && arrayOld[i] !== '') {
						array.push(arrayOld[i]);
					}
				}
				if (array.length > 0) {
					this.$set(this.orderAddParams, 'reserve2', array[0]);
				}
				if (array.length > 1) {
					this.$set(this.orderAddParams, 'customerName', array[1]);
				}
				if (array.length > 2) {
					this.$set(this.orderAddParams, 'phone', array[2]);
				}
				if (array.length > 3) {
					this.$set(this.orderAddParams, 'detailAddress', array[3]);
				}
				if (array.length > 4) {
					this.$set(this.orderAddParams, 'storeName', array[4]);
				}
				if (array.length > 5) {
					this.$set(this.orderAddParams, 'pileModel', array[5]);
				}
				if (array.length > 6) {
					this.$set(this.orderAddParams, 'frameNo', array[6]);
				}
			},
			queryUserList() {
				//所属师傅下拉
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
					params: {
						receivingOrder: this.receivingOrder,
					},
				}).then((res) => {
					this.distributionCol = res.data.data.list;
					this.oldDistributionCol = res.data.data.list;
				});
			},
			distributionOrder(id) {
				this.distribution.orderId = [];
				this.distribution.orderId.push(id);
				this.showDistribution = true;
			},
			onDistribution(val) {
				this.distribution.userId = val.id;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/distribution-order',
					data: this.distribution,
				}).then((res) => {
					if (res.data.code === 200) {
						this.showDistribution = false;
						Toast.success('分配成功');
						this.orderManageList = [];
						this.params.page = 1;
						this.queryOrderList();
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			queryRegionList() {
				this.axios({
					method: 'get',
					url: '/v1/web/region-manage/select-region-drop-down',
					params: this.params,
				}).then((res) => {
					for (let i = 0; i < res.data.data.list.length; i++) {
						let regionObj = {};
						regionObj.value = res.data.data.list[i].id;
						regionObj.label = res.data.data.list[i].regionName;
						this.regionList.push(regionObj);
					}
				});
			},
			dateFormat(time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			goback() {
				this.$router.push('/');
			},
			onLoad() {
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				setTimeout(() => {
					this.params.page = this.params.page + 1;
					this.queryOrderList();
				}, 1000);
			},
			onConfirm(val) {
				this.showPicker = false;
				this.orderAddParams.regionId = val.value;
				this.orderAddParams.regionName = val.label;
			},
			onSubmit() {
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/add-order',
					data: this.orderAddParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.showAdd = false;
						this.orderManageList = [];
						this.params.page = 1;
						this.queryOrderList();
						Toast.success('添加成功');
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			queryOrderList() {
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-order',
					params: this.params,
				}).then((res) => {
					if (this.params.page === 1) {
						this.orderManageList = [];
					}
					for (let i = 0; i < res.data.data.list.length; i++) {
						this.orderManageList.push(res.data.data.list[i]);
					}
					if (res.data.data.list.length <= 0) {
						this.finished = true;
					}
					// 加载状态结束
					this.loading = false;
				});
			},
			isDeleteFun(recordUser, status) {
				if (status === 8) {
					let userId = localStorage.getItem('userId');
					if (recordUser === Number(userId)) {
						return true;
					} else {
						if (authorization('/home-admin/order/order-manage/del')) {
							return true;
						}
						return false;
					}
				}
			},
			deleteOrder(id) {
				this.axios({
					method: 'delete',
					url: '/v1/h5/order-info/delete-order/' + id,
				}).then((res) => {
					if (res.data.code === 200) {
						this.orderManageList = [];
						this.params.page = 1;
						this.queryOrderList();
						Toast.success('删除成功');
					}
				});
			},
		},
	};
</script>

<style></style>
